// transitions for modal
.modal-transition-enter {
  opacity: 0;
  .modal {
    transform: translateY(-100px);
  }
}

.modal-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
  .modal {
    transform: translateY(0);
    transition: transform 300ms;
  }
}

.modal-transition-exit {
  opacity: 1;
  .modal {
    transform: translateY(0);
  }
}

.modal-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
  .modal {
    transform: translateY(-100px);
    transition: transform 300ms;
  }
}

// transitions for map
.map-transition-enter {
  opacity: 0;
  transform: translateY(200px);
}

.map-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms 300ms;
}

.map-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.map-transition-exit-active {
  opacity: 0;
  transform: translateY(200px);
  transition: all 500ms 300ms;
}

// transitions for big block
.block-transition-enter {
  opacity: 0;
  transform: translateY(200px);
}

.block-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms;
}

// transitions for big block (from left to right)
.block-left-transition-enter {
  opacity: 0;
  transform: translateX(-200px);
}

.block-left-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

// transitions for big block (from right to left)
.block-right-transition-enter {
  opacity: 0;
  transform: translateX(200px);
}

.block-right-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

// transitions for top button
.top-button-transition-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.top-button-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms 500ms;
}

// transitions for bottom button
.bottom-button-transition-enter {
  opacity: 0;
  transform: translateY(100%);
}

.bottom-button-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms 500ms;
}

// transitions for sidebar items
@for $i from 0 through 20 {
  $delay: $i * 100;

  .sidebar-item-#{$i}-transition-enter {
    opacity: 0;
    transform: translateY(24px);
  }

  .sidebar-item-#{$i}-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 200ms #{$delay}ms;
  }
}

// transitions for list
@for $i from 0 through 100 {
  $delay: $i * 100;

  .list-#{$i}-transition-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  .list-#{$i}-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms #{$delay}ms;
  }
}

// faster transitions for list
@for $i from 0 through 100 {
  $delay: $i * 100;

  .list-fast-#{$i}-transition-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  .list-fast-#{$i}-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 200ms #{$delay}ms;
  }
}

// transitions for form (visningsliste)
@for $i from 0 through 10 {
  $delay: $i * 100;

  .form-#{$i}-transition-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  .form-#{$i}-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 500ms #{$delay}ms;
  }
}
