.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.6);
  z-index: 10000;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 432px) {
    padding-left: 0;
    padding-right: 0;
  }

  &.modal-centered {
    display: flex;
    align-items: center;
    justify-content: center;

    .modal {
      margin: 0;
    }
  }
}

.modal {
  color: #323232;
  width: 900px;
  margin: 50px auto 0;

  &__main {
    position: relative;
    background: #FFFFFF;
    height: 100%;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5em;
    text-align: center;

    &_small {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
    }
  }

  &__wrapper {
    padding: 15px;
    @media (min-width: 432px) {
      padding: 30px 45px;
    }

    &_p30 {
      padding: 30px;
    }
  }

  &__inner {
    padding: 30px;
    background-color: #F7F7F7;

    &_pt40 {
      padding-top: 40px;
    }
  }

  &__area-parent {
    padding-top: 15px;
  }

  &__area {
    width: 100%;
    min-height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  &__input {
    display: none;

    &:checked {
      ~ .modal__label {
        background-color: #323232;
        color: #fff;
        padding: 7px 0;
        padding-left: 20px;
        padding-right: 30px;

        &:before {
          display: block;
        }
      }
    }
  }

  &__label {
    min-height: 41px;
    width: 215px;
    border: 1px solid rgba(50, 50, 50, 0.5);
    padding: 7px 0;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 4px;
    margin-bottom: 4px;

    &:before {
      content: "";
      display: none;
      width: 20px;
      min-width: 20px;
      height: 15px;
      background: url(../App/images/moda-check.svg) no-repeat 50%;
      z-index: 100;
      margin-right: 15px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;

    &_svg-color {
      svg {
        fill: #323232;
      }
    }

  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    margin-right: -4px;
    margin-top: 20px;
  }

  &__text-content {
    width: 100%;
    height: 216px;
    font-size: 14px;
    line-height: 1.3em;
    color: #323232;
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    p:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__event {
    &-list {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      width: 50%;
      margin-bottom: 20px;

      .event-comment {
        background: url("../App/images/icons/comment-dark.svg") left 2px no-repeat;
        padding: 0 24px;
      }
    }

    &-list {
      margin-top: 30px;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5em;
      margin-bottom: 10px;
    }
  }

  &__box-btns {
    display: flex;
    justify-content: center;
  }

  &__section {
    margin-bottom: 20px;

    &-list {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      margin-bottom: 15px;
      width: 50%;
      padding-right: 16px;
    }
  }

  &__custom-input {
    margin-left: 15px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
    font-size: 16px;
    line-height: 1.5em;
    color: #757575;
    background-color: transparent;

    &.is-checked {
      color: #323232;
      border-bottom-color: #323232;
    }
  }
}

.modal.modal-fullscreen {
  max-width: 1366px;
  width: 100vw;
  min-width: 1024px;
  max-height: 1024px;
  height: 100%;
  padding: 20px;
  top: 0;
  margin-top: 0;

  .modal__content {
    display: flex;
    height: 100%;

    .modal__wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .modal__text-content {
        flex-grow: 1;
      }
    }

    .modal__inner {
      width: 334px;
      max-width: 334px;
      min-width: 334px;
      display: flex;
      flex-direction: column;

      .modal__section {
        flex-grow: 1;

        .modal__section-list {
          display: block;

          .modal__section-item {
            width: auto;
            padding-right: 0;

            .checkbox-row__label {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
