.partners-preloader {
  position: relative;
  overflow: hidden;
  height: 60px;
  min-width: 60px;
  transform: scale(3);
}

.partners-preloader svg {
  fill: #fff;
}

.partners-preloader span {
  display: block;
  position: absolute;
  height: 4px;
  width: 60px;
  background-color: #fff;
}

//.logo span:nth-child(2) {
//  left: -60px;
//  top: 0;
//  animation: preloader2 0.95s 2.96s linear forwards;
//}
//
//.logo span:nth-child(3) {
//  left: 60px;
//  top: auto;
//  bottom: 0;
//  animation: preloader3 0.95s linear forwards;
//}
//
//.logo span:nth-child(4) {
//  left: -28px;
//  top: 100px;
//  transform: rotate(90deg);
//  animation: preloader4 0.95s 0.3s linear forwards;
//}
//
//.logo span:nth-child(5) {
//  right: -28px;
//  left: auto;
//  top: -34px;
//  transform: rotate(90deg);
//  animation: preloader5 0.95s 0.9s linear forwards;
//}

$animation-time: 0.65s;

.partners-preloader.animate span:nth-child(3) {
  left: 60px;
  top: auto;
  bottom: 0;
  animation: preloader3_hover $animation-time 1s linear forwards;
}

.partners-preloader.animate span:nth-child(4) {
  left: -28px;
  top: 100px;
  transform: rotate(90deg);
  animation: preloader4_hover $animation-time 1s + $animation-time * 0.8 linear forwards;
}

.partners-preloader.animate span:nth-child(2) {
  left: -60px;
  top: 0;
  animation: preloader2_hover $animation-time 1s + $animation-time * 1.8 linear forwards;
}

.partners-preloader.animate span:nth-child(5) {
  right: -28px;
  left: auto;
  top: -34px;
  transform: rotate(90deg);
  animation: preloader5_hover $animation-time 1s + $animation-time * 2.7 linear forwards;
}

.partners-preloader {
  opacity: 1;
  // animation: logoHidden 2.5s linear forwards;
}

@keyframes logoHidden {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//@keyframes preloader5 {
//  100% {
//    top: 14px;
//  }
//}
//
//@keyframes preloader2 {
//  100% {
//    left: 0;
//  }
//}
//
//@keyframes preloader4 {
//  100% {
//    top: 32px;
//  }
//}
//
//@keyframes preloader3 {
//  100% {
//    left: 0;
//  }
//}

@keyframes preloader5_hover {
  100% {
    top: 14px;
  }
}

@keyframes preloader2_hover {
  100% {
    left: 0;
  }
}

@keyframes preloader4_hover {
  100% {
    top: 32px;
  }
}

@keyframes preloader3_hover {
  100% {
    left: 0;
  }
}
