.clients-counter {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5em;
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  align-items: baseline;
  .clients-counter-total {
    font-size: 20px;
    min-width: 70px;
    text-align: center;
  }
}
