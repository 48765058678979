.preloader-enter {
  opacity: 0;
}
.preloader-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.preloader-exit {
  opacity: 1;
}
.preloader-exit-active {
  opacity: 0;
  transition: opacity 750ms;
}

.preloader-backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1090;
  background-color: #323232;
  display: flex;
  align-items: center;
  justify-content: center;
}
