.back-link-absolute {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 15px;
  @media (min-width: 432px) {
    top: 36px;
    left: 90px;
  }
}
