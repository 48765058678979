@keyframes spinx {
  0% {
      width: 100%
  }
  50% {
    width: 0%
  }
  100% {
    width: 100%
  }
}

.c-side-bar {
  height: 100%;
  overflow: hidden;
  max-width: 90px;
  min-width: 90px;
  padding: 20px 0 30px;
  background-color: #2E2E2E;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation-fill-mode: both;

  &__nav {
    width: 100%;
    height: 620px;
  }

  &__menu {
    text-align: center;

    &-item {
      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        margin-bottom: -79px;
        width: 90px;
        height: 190px;
        pointer-events: visible;
        box-shadow: 20px 0 15px 0 rgba(0, 0, 0, 0.15);
        transform: matrix(0.58, -0.82, -0.82, -0.58, 0, 0);

        &::before {
          content: '';
          background-color: transparent;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        svg {
          fill: #fff;
          transform: inherit;
          z-index: 1;
        }

        &.is-active {
          // background-color: #fff;
          z-index: 30;
          pointer-events: none;
          // transition: 0.5s;
          &::before {
            background-color: #fff;
            margin: 0 auto 0 auto;
            animation: spinx 0.5s ease-out;
          }

          svg {
            fill: #2e2e2e;
            transition: fill 0.3s;
          }

          &:last-child {
            a {
              box-shadow: none;
            }
          }

        }
      }

      &:last-child {
        a {
          box-shadow: 20px 0 15px 0 rgba(0, 0, 0, 0.15), -15px 0px 15px 0px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

.slide-left-animation {
  &-enter {
    animation-name: slideInLeft;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
  }

  &-exit {
    animation-name: slideOutRight;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
