.preview-email {
  width: 100% !important;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', arial, sans-serif !important;
  overflow-y: auto;

  a {
    display: inline;
  }

  p {
    margin: 1em 0;
  }

  img {
    outline: none;
    text-decoration: none;
    border: none;
    -ms-interpolation-mode: bicubic;
    max-width: 100% !important;
    margin: 0;
    padding: 0;
    display: inline;
  }

  .list {
    margin-bottom: 15px;

  }

  table td {
    border-collapse: collapse;
  }

  table {
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
  }

  .btn {
    width: 200px;
    text-align: center;
    background-color: #DBDBDB;
    font-size: 12px;
    font-weight: 500;
    height: 42px;
  }

  @media (max-width: 768px) {
    .header-td {
      width: 68% !important;
    }
    .table-images td {

    }

  }

  @media (max-width: 600px) {

    p {
      font-size: 9px !important;
    }

    .padding {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }


    .table-footer > tbody > tr > td {
      display: block;
    }

    .footer-td {
      margin-top: 20px;
    }

    .table-btn > tbody > tr > td {
      display: block;
    }


  }

  @media (max-width: 400px) {
    .td-object span {
      display: block;
      margin-top: 10px;
      padding-left: 0 !important;
    }
  }
}
