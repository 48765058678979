@media (max-width: 1100px) {
  .c-tile__content {
    font-size: 16px;
  }
  .c-tile__title {
    font-size: 12px;
  }
}

@media (max-height: 640px) {
  .main .add-file {
    margin-top: 19px;
  }

  .main .c-side-bar__menu-item a {
    height: 175px;
    margin-bottom: -69px;
  }

  .main .c-side-bar {
    padding: 15px 0 15px;
  }
}
