.toast {
  position: absolute;
  right: 30px;
  bottom: 32px;
  padding: 20px;
  background-color: #323232;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
  z-index: 1080;
  display: flex;
  align-items: center;
}
