.react-select-container {
  width: 300px;
  margin: 0 auto;
  .react-select__menu {
    .react-select__menu-list {
      padding: 8px;
    }
  }
  .react-select__control {
    background: transparent;
    border-radius: 0;
    border: none;
    justify-content: center;
    &.react-select__control--is-focused {
      box-shadow: none;
    }
    .react-select__dropdown-indicator {
      & > svg {
        transition: 0.15s;
      }
    }
    &.react-select__control--menu-is-open {
      .react-select__dropdown-indicator {
        & > svg {
          transform: rotate(180deg);
        }
      }
    }
    &.react-select__control--is-disabled {
      .react-select__dropdown-indicator {
        display: none;
      }
    }
  }

  .react-select__value-container {
    display: block;
    padding: 0;
    flex: 0 0 auto;
    & > input {
      position: absolute;
    }
  }

  .react-select__single-value {
    color: #FFFFFF;
    font-size: 24px;
    max-width: none;
    position: static;
    transform: none;
    margin: 0;
  }

  .react-select__indicators {
    .react-select__indicator {
      color: #FFFFFF;
    }
    .react-select__indicator-separator {
      background: transparent;
    }
  }

  .react-select__menu {
    border-radius: 0;
  }
  .react-select__option {
    color: #323232;
    padding: 6px 16px;
    &.react-select__option--is-focused {
      background-color: #D7D7D7;
    }
    &.react-select__option--is-selected {
      color: #FFFFFF;
      background-color: #323232;
    }
  }
}
