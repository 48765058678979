// KALENDER - START

.fc .fc-toolbar-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5em;
  width: 260px;
  text-align: center;
  text-transform: capitalize;
}

.fc .fc-theme-standard td, .fc.fc-theme-standard th {
  border: none;
}

.fc .fc-scrollgrid-liquid {
  border: none;
}

.fc .fc-col-header-cell {
  background-color: #323232;
}

.fc td.fc-daygrid-day {
  background-color: #323232;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
  padding: 15px;
  border: none;
}

.fc .fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc .fc-col-header-cell-cushion {
  padding: 7px 4px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5em;
  text-transform: capitalize;
}

.fc.fc-direction-ltr .fc-daygrid-week-number {
  top: -15px;
  left: -15px;
  background-color: transparent;
  font-size: 12px;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc .fc-daygrid-day-number {
  padding-bottom: 0;
  width: 100%;

  .icon-comment {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background: #4C4C4C
}

.fc .fc-toolbar.fc-header-toolbar {
  position: relative;
}

.fc .fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.fc .fc-toolbar-chunk > .fc-prev-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.fc .fc-toolbar-chunk > .fc-next-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:focus {
  box-shadow: none;
  background-color: transparent;
}

.fc .fc-scroller,
.fc .fc-scroller-harness {
  overflow: visible !important;
}

.fc .fc-day-past .fc-h-event {
  opacity: 0.3;
}

.fc {
  .fc-day-past {
    .fc-daygrid-day-top {
      opacity: 0.3;
    }

    &.fc-day-other {
      .fc-daygrid-day-top {
        opacity: 0.3;
      }
    }
  }

  .fc-day-other {
    .fc-daygrid-day-top {
      opacity: 0.6;
    }
  }
}

.fc .fc-h-event {
  background-color: transparent;
  border: none;
  font-size: 12px;
  line-height: 1.3em;
}

.fc .fc-daygrid-more-link {
  font-size: 12px;
  line-height: 1.3em;
  background: #575757;
  opacity: 0.9;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 4px 0;
  border-radius: 0;
}

.fc .fc-daygrid-day-events .fc-daygrid-event-harness {
  &:last-child {
    > a {
      margin-bottom: 5px;
    }
  }
}

.fc .fc-daygrid-day-bottom {
  margin: 0;
  margin-top: 4px;
}

.fc-popover .fc-popover-body .fc-event-main {
  color: #323232;
}

.fc .fc-daygrid-event {
  border-radius: 0;

  .fc-event-title {
    // white-space: normal;
  }
}

.fc .fc-scrollgrid-section > td {
  border: none;
}

.fc .fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.fc .fc-header-toolbar {
  .fc-toolbar-chunk {
    &:nth-child(2) {
      position: absolute;
      left: 50%;
    }
    .fc-button-group {
      .fc-button {
        background-color: #DBDBDB;
        color: #323232;
        border: none;
        border-radius: 0;
        padding-left: 20px;
        padding-right: 20px;
        &.fc-button-active {
          background-color: #4C4C4C;
          color: #ffffff;
        }
      }
    }
  }
}

.fc .fc-timegrid.fc-timeGridWeek-view {
  table.fc-scrollgrid-liquid {
    thead {
      .fc-col-header {
        colgroup {
          col {
            width: 0 !important;
          }
        }
      }

      .fc-timegrid-axis-frame {
        position: absolute;
        left: 6px;

        .fc-timegrid-axis-cushion {
          padding: 0;
          font-size: 12px;
          font-weight: normal;
          color: #808080;
        }
      }
    }

    tbody {
      .fc-scrollgrid-section:nth-child(1) {
        .fc-scroller-harness {
          height: 100%;

          .fc-scroller {
            height: 100%;

            .fc-daygrid-body {
              height: 100%;

              .fc-scrollgrid-sync-table {
                height: 100%;

                .fc-timegrid-axis {
                  border: none;

                  .fc-timegrid-axis-cushion {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .fc-scrollgrid-section:nth-child(2) {
        display: none;
      }

      .fc-scrollgrid-section:nth-child(3) {
        display: none;
      }
    }
  }
}

// KALENDER - END
