// SWIPER - START

.swiper-container-thumbs {
  margin-top: 7px;
  height: 66px;
  .swiper-slide {
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.swiper-container-main {
  height: calc(100% - 74px);
}

.swiper-slide-content {
  height: 100%;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.swiper-slide-desc {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  line-height: 1.3em;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 6px 16px 6px 80px;
  & > p {
    height: 32px;
    display: flex;
    align-items: center;
    //overflow: hidden;
  }
}

.swiper-pagination-fraction {
  bottom: 14px;
  font-size: 14px;
  line-height: 1.3em;
  position: absolute;
  left: 20px;
  z-index: 10;
}

// SWIPER - END
