.leads-list {
  .back-link {
    display: none;
    @media (min-width: 432px) {
      position: absolute;
      top: 30px;
      display: block;
      z-index: 1;
    }
  }
  .back-link-mobile {
    position: absolute;
    top: 0;
    @media (min-width: 432px) {
      display: none;
    }
  }

  .leads-list-wrapper {
    width: 100%;
    margin: 0 auto;
    overflow-x: visible;
    overflow-y: auto;
    height: 100%;
    padding: 0;
    @media (min-width: 432px) {
      padding: 0 40px 0 80px;
    }

    .leads-list-heading {
      display: none;
      @media (min-width: 432px) {
        font-size: 18px;
        font-weight: 500;
        display: flex;
        margin-bottom: 20px;
        margin-top: 10px;
      }
    }

    .block-visninglist__logo {
      margin-top: 0;
      @media (min-width: 432px) {
        display: none;
      }
    }

    .leads-list-item {
      box-shadow: none;
      background: linear-gradient(0deg, #464646, #515151);
      display: flex;
      flex-wrap: wrap;
      @media (min-width: 432px) {
        background: #545454;
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5em;
        align-items: center;
        flex-wrap: nowrap;
      }

      .leads-list-item-name {
        width: 50%;
        padding: 10px;
        @media (min-width: 432px) {
          width: auto;
          flex-grow: 1;
          padding: 13px 40px;
        }
      }

      .leads-list-item-phone {
        margin-top: 10px;
        display: block;
        @media (min-width: 432px) {
          display: inline;
          margin-top: 0;
        }
      }

      .leads-list-item-date {
        width: 50%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (min-width: 432px) {
          display: block;
          width: 210px;
          min-width: 210px;
          padding: 13px 40px;
        }
      }

      .leads-list-item-buttons {
        width: 100%;
        padding: 0 10px 10px;
        display: flex;

        .button {
          width: 50%;
        }

        @media (min-width: 432px) {
          display: block;
          width: 292px;
          min-width: 292px;
          padding: 13px 40px;
          .button {
            width: auto;
            font-size: 14px;
          }
        }
      }
    }
  }
}
