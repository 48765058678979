@import 'shame';
@import 'helpers';

html,
body {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

body {
  background-color: #4c4c4c;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
}

.main {
  background-image: url(./images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  display: flex;
  margin: 0 auto;
  width: 100vw;
  height: 100%;
  position: relative;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  @media (min-width: 432px) {
    max-width: 1366px;
    width: 100vw;
    min-width: 1024px;
    max-height: 1024px;
  }
}

.button {
  color: #323232;
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
  padding: 8px 24px;
  border: none;
  cursor: pointer;
  background-color: #DBDBDB;
  font-family: inherit;
  margin: 0;
  transition: 0.3s;

  &:hover {
    background-color: #FFFFFF;
  }

  &.hover-dark {
    &:hover {
      background-color: #323232;
      color: #fff;
    }
  }

  &:disabled, &:disabled:hover, &:disabled.hover-dark:hover {
    background-color: #e5e5e5;
    color: #919191;
    cursor: default;
  }

  &.disabled-transparent {
    &:disabled, &:disabled:hover, &:disabled.hover-dark:hover {
      background-color: rgba(229, 229, 229, 0.12);
    }
  }
}

.button-block {
  display: block;
  width: 100%;
}

.button-sm {
  padding: 4px 16px;
}

.button-lg {
  padding: 18px 24px;
}

.button-default {
  color: #323232;
  position: relative;
  width: fit-content;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
  padding: 8px 24px;
  border: none;
  cursor: pointer;
  z-index: 50;
  background-color: #DBDBDB;
  font-family: inherit;
  margin: 0;

  &_mb35 {
    margin-bottom: -35px;
  }

  &_mlauto {
    margin-left: auto;
  }

  &_w210 {
    width: 210px;
  }

  &_padding {
    padding: 13px 0;
  }

  &_ml20 {
    margin-left: 20px;
  }

  &_w100 {
    width: 100%;
  }

  &.button-red {
    background-color: #e57575;
    color: #fff;

    &:hover, &:focus {
      background-color: #d56464;
    }
  }
}

// L GRID- START 

.l-grid {
  padding: 0 15px 15px;
  width: 100%;
  overflow: hidden;
  @media (min-width: 432px) {
    padding: 0 30px 30px;
  }

  &_bg {
    background-image: url(./images/visninger_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  &__content {
    height: 100%;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &__inner {
    margin-top: 40px;
    height: calc(100% - 100px);

    // shame!!!
    &_pb0 {
      margin-top: 15px;
      height: calc(100% - 15px);
      @media (min-width: 432px) {
        margin-top: 30px;
        height: calc(100% - 30px);
      }
    }

    &_mt0 {
      margin-top: 0;
      height: 100%;
    }
  }

  &__section {
    margin-top: 15px;
    flex-grow: 1;
    @media (min-width: 432px) {
      margin-top: 40px;
    }

    &_overflow {
      overflow-y: auto;
      overflow-x: hidden;
    }

    &_indents {
      padding: 28px 20px 0 25px;
      margin: 10px -20px 0 -25px;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }
    }

    &_mt20 {
      margin-top: 20px;
    }

    &_mt0 {
      margin-top: 0;
    }

    &_mr15pr15 {
      padding-right: 15px;
      margin-right: -15px;
    }

    &_mr-15ml7pr18 {
      padding-right: 18px;
      margin-right: -15px;
      margin-left: 7px;
    }
  }

  &__map {
    margin-left: 30px;
    max-width: 45%;
    width: 100%;
    height: 100%;
    flex-shrink: 0;

    &_long {
      max-width: none;
      flex-grow: 1;
      width: auto;
      margin-left: 20px;
    }

    &_w55 {
      max-width: 55%;
    }

    &_ml0 {
      margin-left: 0;
    }

    &_flex-column {
      display: flex;
      flex-direction: column;
    }
  }

  &__sidebar {
    width: 320px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_mwidth {
      max-width: 1020px;
      margin: 0 auto;
    }
  }
}

// L GRID - END 

// TILE COMPONENT - START
.c-tile {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;

  &__item {
    padding: 20px;
    display: flex;
    width: 33%;
    min-height: 160px;
    flex-direction: column;
    justify-content: center;
    background-color: #323232;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
    position: relative;
  }

  &__title {
    font-size: 14px;
    line-height: 1.5em;
  }

  &__content {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: 4px;
    word-break: break-all;
  }

  .c-tile-item-edit {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

// TILE COMPONENT - END

// CHECKBOX - START 

.checkbox-row {
  display: flex;
  align-items: center;
  width: 100%;

  &_w-auto {
    width: auto;
  }

  &_w48 {
    width: 100%;
    margin-bottom: 8px;
    @media (min-width: 432px) {
      width: 49%;
      margin-bottom: 0;
    }
  }

  &_mr6 {
    margin-right: 6px;
    margin-bottom: 6px;
  }

  &__item {
    width: 16px;
    min-width: 16px;
    height: 16px;
    position: relative;
    background-color: transparent;
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.8);
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: 0.3s;

    &:checked {
      background-color: #fff;
      opacity: 1;

      &:before {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 11px;
      height: 9px;
      background: url(./images/check.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      left: 2px;
      top: 3px;
      z-index: 100;
      opacity: 0;
    }

    &_black-border {
      border: 1px solid #323232;

      &:checked {
        background-color: #323232;

        &:before {
          background: url(./images/check-white.svg) no-repeat center;
        }
      }
    }

    &_hidden {
      display: none;

      &:checked ~ label {
        background-color: #fff;
        color: #323232;
        opacity: 1;

        svg {
          opacity: 1;
          fill: #323232;
        }
      }
    }
  }

  &__label {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5em;
    padding-left: 15px;
    width: 100%;
    display: flex;
    align-items: center;

    &_whole {
      padding: 7px 13px;
      width: fit-content;
      font-size: 12px;
      opacity: 0.9;
      background-color: rgba(255, 255, 255, 0.2);

      svg {
        margin-right: 10px;
        opacity: 0.3;
        fill: #fff;
        @media (min-width: 432px) {
          margin-right: 6px;
        }
      }
    }

    &_fw400 {
      font-weight: 400;
    }

    &_w100 {
      width: 100%;
    }

    &_w48 {
      width: 48%;
    }
  }
}


// CHECKBOX- END 


// SEARCH START 

.search-row {
  width: 100%;
  position: relative;

  &_icon {
    &:before {
      content: '';
      width: 25px;
      height: 25px;
      position: absolute;
      right: 0;
      top: 10px;
      background: url(./images/search.svg) no-repeat center;
    }
  }

  &.is-active {
    &:before {
      display: none;
    }
  }

  &_mwidth {
    max-width: 500px;
  }

  &_mauto {
    margin: 0 auto 0;
  }

  &_mt45 {
    margin-top: 45px;
  }

  &__item {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 15px 0 8px;
    font-size: 14px;
    line-height: 1.5em;
    color: #fff;
    border-radius: 0;

    &::-webkit-search-cancel-button {
      position: relative;
      right: 0;
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      background: url(../App/images/close.svg) no-repeat center;
    }

    &.is-active {
      &::-webkit-search-cancel-button {
        opacity: 1 !important;
      }

    }
  }

  &__label {
    font-size: 12px;
    line-height: 1.5em;
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: -5px;
    transition: 0.5s;

    &_top15 {
      top: 15px;

    }
  }
}


// SEARCH - END 


// LIST-TOGGLE - COMPONENTS - START 

.c-list-toggle {
  max-width: 320px;

  &__item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__box {
    display: flex;
    align-items: center;

    &.is-clicked {
      .c-list-toggle__arrow {
        transform: rotate(180deg);
        transition: 0.5s;
        transform-origin: 30% 50%;
      }
    }
  }

  &__icon {
    margin-right: 25px;
    line-height: 0;
  }

  &__arrow {
    line-height: 0;
    padding-right: 15px;
    transform-origin: 30% 50%;
    transition: 0.5s;
  }

  &__hidden {
    display: none;
    margin-top: 20px;

    &.is-clicked {
      display: block;
    }

    &-row {
      display: flex;
      padding: 5px 10px;
      justify-content: space-between;

      //&:not(:last-child) {
      //  margin-bottom: 15px;
      //}

      svg {
        fill: #fff;
      }

      &.is-clicked {
        background-color: #fff;
        color: #323232;

        svg {
          fill: #323232;
        }
      }
    }

    &-text {
      font-size: 14px;
      line-height: 1.5em;
      max-width: 200px;
      width: 100%;
    }

    &-box {
      display: flex;
      align-items: center;
      height: 100%;
      min-width: 64px;
      flex-shrink: 0;
    }

    &-icon {
      padding-right: 10px;
      line-height: 0;
    }

    &-distance {
      font-weight: 500;
      font-size: 12px;
      line-height: 1.5em;
    }
  }
}

// LIST-TOGGLE - COMPONENTS - END 


// C-LIST - COMPONENTS - START 

.c-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  counter-reset: numb;

  &_width975 {
    max-width: 975px;
  }

  &_width580 {
    max-width: 580px;
  }

  &_width760 {
    max-width: 760px;
  }

  &_width-auto {
    margin: 0 auto;
  }

  &__title {
    max-width: 280px;

    &:before {
      counter-increment: numb;
      content: counter(numb) ". ";
    }
  }

  &__item {
    width: 33%;
    margin-bottom: 60px;
    overflow: hidden;

    &_long {
      width: 86%;
      display: flex;
      margin: 0 auto 60px;

      .c-list__icon-box {
        .c-list__icon {
          min-width: 90px;
        }
      }
    }

    &_1column {
      width: 100%;
    }

    &_2column {
      width: 47%;
    }

    &_d-flex {
      display: flex;
    }

    &_indent {
      padding-bottom: 40px;
      margin-bottom: 20px;
    }

    &:last-child {
      .c-list__icon-line {
        &:after {
          content: none;
        }
      }
    }
  }

  &__content {
    margin-top: 15px;

    &_long {
      margin-top: 0;
      margin-left: 50px;
    }

    &_ml80 {
      margin-left: 80px;
    }

    &_mt0 {
      margin-top: 0;
    }

    &_mt30 {
      margin-top: 30px;
    }
  }

  &__sublist {
    margin-top: 15px;

    &_column {
      column-count: 2;
      column-gap: 20px;

      .c-list__subitem {
        max-width: 100%;
      }
    }
  }

  &__subitem {
    margin-bottom: 7px;
    font-size: 14px;
    line-height: 1.5em;
    max-width: 285px;

    &_long {
      max-width: 440px;
    }
  }

  &__icon {
    min-height: 42px;

    &_width {
      min-width: 50px;
      padding-top: 5px;
      min-height: auto;
      text-align: center;
    }

    &-line {
      &:after {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background-color: #fff;
        margin: 30px auto 0;
      }
    }
  }

  &__desc {
    font-size: 14px;
    line-height: 1.5em;
    max-width: 305px;

    &_long {
      max-width: 360px;
    }
  }
}


// C-LIST - COMPONENTS - END 

// FILTER - START 

.c-filter {
  margin-left: 30px;

  &__name {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5em;
  }

  &__title {
    &-count {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5em;
    }

    &-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5em;
    }
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &_mb40 {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    &_mb35 {
      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }

    &_text-align-right {
      text-align: right;
    }
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    margin-right: -7px;
  }

  &__range {
    padding-top: 10px;
    width: 100%;

    &_short {
      width: 90%;
    }

  }

  &__desc {
    display: block;
    font-size: 12px;
    line-height: 1.3em;
    opacity: 0.5;
  }

}


// FILTER - END 

// SELECTED - start  

.c-selected {
  display: flex;
  justify-content: space-between;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    max-width: 100%;

    &_width400 {
      max-width: 400px;
    }
  }

  &__item {
    margin-left: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5em;
    color: #323232;
    display: flex;
    align-items: center;
    padding-left: 10px;

    &_color {
      background-color: #323232;
      color: #fff;
    }
  }
}

.remove-select {
  display: block;
  padding: 10px;
  line-height: 0;

  svg {
    fill: #323232;

  }

  &__svg-color {
    svg {
      fill: #fff;
    }
  }
}

// SELECTED - END 


// LIST - RESULT - START

.c-list-result {
  margin-top: 40px;

  &__caption {
    display: flex;

    li {
      max-width: 50%;
      width: 100%;
      padding: 0 40px;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.5em;
    }
  }

  &__items {
    margin-top: 20px;
  }

  &__item {
    display: flex;
    background-color: #323232;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5em;
  }

  &__name,
  &__date {
    max-width: 50%;
    width: 100%;
    padding: 13px 40px;
  }

  &__name {
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }
}

// LIST - RESULT - END 

// PROPERTY - START 

.c-property {
  padding-top: 40px;

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    width: 100%;
    padding: 20px;
    background-color: #323232;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.5);
    @media (min-width: 432px) {
      width: 50%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    max-width: 440px;
    min-height: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5em;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.5em;

    svg {
      margin-left: 15px;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }
}

// PROPERTY - END 
.map-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #e5e3df;
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #FFFFFF;
  //-webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

@import 'react-select';
@import 'media';
@import 'forms';
@import 'transitions';
