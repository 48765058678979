.swiper-container-main {
  height: 100%;
}

.swiper-slide-content {
  height: 100%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.swiper-pagination-fraction {
  bottom: 14px;
  font-size: 14px;
  line-height: 1.3em;
  position: absolute;
  left: 20px;
  z-index: 10;
  color: #000;
}

.swiper-button-prev, .swiper-button-next {
  color: #000;

  &:after {
    font-size: 36px;
  }
}
