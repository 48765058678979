.edit-value-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc;
  font-size: 16px;
  line-height: 2em;
  color: #323232;
  background-color: transparent;
  transition: border-bottom-color 0.3s;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-bottom: 1px solid #757575;
  }
}
