// TABS - COMPONENT - START 
.c-tab-bar {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.35);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__item {
    a {
      width: 100%;
      padding: 20px 0 16px;
      color: #FFF;
      opacity: 0.8;
      font-size: 14px;
      text-align: center;
      font-weight: 500;
      line-height: 1.5em;
      position: relative;
      transition: 0.5s;
    }
  }

  .c-tab-bar__active-indicator {
    position: absolute;
    bottom: -1px;
    height: 3px;
    background-color: #ffffff;
    transition: 0.5s;
    z-index: 1;
  }
}

// TABS - COMPONENT - END
