.marker-icon {
  position: relative;
  width: 26px;
  height: 26px;

  &__info-box {
    position: absolute;
    bottom: 22px;
    left: 26px;
    padding: 0 0 29px 28px;
    background: url("../App/images/marker-info-box-link.svg") left bottom no-repeat;
    z-index: 1;
  }

  &__label {
    background: #323232;
    font-size: 12px;
    padding: 8px 15px;
    white-space: nowrap;
  }

  &.current {
    width: 26px;
    height: 26px;

    .marker-icon__info-box {
      bottom: 21px;
      left: 22px;
      padding: 0 0 29px 28px;
    }
  }
}
