.input-select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 5px;

  &.input-select-block {
    width: 100%;
  }
}

.block-visninglist {
  max-width: 830px;
  width: 100%;
  margin: -48px auto 0px;
  @media (min-width: 432px) {
    height: 99%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__header {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 432px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__logo {
    margin-bottom: 30px;
    margin-top: 0;
    @media (min-width: 432px) {
      margin-top: 0;
      margin-bottom: 10px;
      width: 220px;
    }

    img {
      margin: 0 auto;
      max-height: 50px;
      @media (min-width: 432px) {
        max-height: 60px;
      }
    }
  }

  &__adress {
    max-width: 530px;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
  }

  &__price {
    font-size: 20px;
    line-height: 1.5em;
  }

  &__body {
    padding-top: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    @media (min-width: 432px) {
      flex-direction: row;
    }
  }

  &__form {
    max-width: 530px;
    width: 100%;

    &-row {
      position: relative;
      margin-bottom: 26px;

      .form-error {
        @media (min-width: 432px) {
          position: absolute;
          right: 0;
          bottom: 11px;
        }
      }
    }

    &-item {
      display: block;
      width: 100%;
      padding: 0 20px 10px;
      color: #fff;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.8);
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &-label {
      position: absolute;
      left: 0;
      top: -10px;
      padding: 0 20px;
      font-size: 16px;
      line-height: 1.5em;
      color: rgba(255, 255, 255, 0.8);
      transition: 0.5s;

      &.is-focus {
        font-size: 12px;
        top: -20px;
      }
    }

    &-checkboxes {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (min-width: 432px) {
        margin-bottom: 26px;
      }
    }
  }

  &__manager {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    @media (min-width: 432px) {
      flex-direction: column;
      width: 220px;
      margin-bottom: 0;
    }

    &-img {
      margin-bottom: 15px;

      img {
        height: 106px;
        @media (min-width: 432px) {
          height: auto;
        }
      }
    }

    &-name {
      font-size: 22px;
      line-height: 1.5em;
    }

    &-content {
      text-align: left;
      padding-left: 16px;
      padding-top: 6px;
      @media (min-width: 432px) {
        text-align: center;
        padding-left: 0;
        padding-top: 0;
      }
    }

    &-specialization {
      margin-bottom: 15px;
    }

    &-specialization,
    &-phone {
      font-size: 14px;
      line-height: 1.5em;
    }

  }
}