.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 432px) {
    padding-right: 0;
    padding-left: 0;
  }

  .user-info-content {
    margin-left: 15px;
    text-align: left;
    font-size: 12px;
    @media (min-width: 432px) {
      margin-left: 30px;
    }
  }
}

.user-footer {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 15px 15px;
  width: 100%;
  font-size: 12px;
  @media (min-width: 432px) {
    padding: 0 0 68px 0;
    width: 320px;
    font-size: 16px;
  }

  .user-footer-link {
    color: #999999;
    text-decoration: underline;
  }
}
