@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-Regular.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/Gilroy-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/Gilroy-Regular.woff') format('woff'), /* Pretty Modern Browsers */
         url('./fonts/Gilroy-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-Medium.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/Gilroy-Medium.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/Gilroy-Medium.woff') format('woff'), /* Pretty Modern Browsers */
         url('./fonts/Gilroy-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 500;
    font-display: swap;
}

@font-face {
  font-family: 'AsapWeb';
  src: url('./fonts/asap-m2pr-webfont.woff2') format('woff2'),
  url('./fonts/asap-m2pr-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: 'AsapWeb';
  src: url('./fonts/asapbold-kmmp-webfont.woff2') format('woff2'),
  url('./fonts/asapbold-kmmp-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0030-0039;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #fff;
  font-family: 'AsapWeb', 'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  display: block;
  color: inherit;
  text-decoration: none;
}

input, textarea  {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

textarea {
resize: none;
}

h1, h2, h3, h4, h5, h6 {
margin: 0;
padding: 0;
font-feature-settings: 'pnum' on, 'lnum' on;
}

h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 1.5em;
}

h3{
  font-weight: 500;
  font-size: 22px;
  line-height: 1.5em;
}

div, nav, p, span, button, input, a, form, ul, li, textarea, *:before, *:after {
  box-sizing: border-box;
}

a, a:hover, a:active, a:focus, button, input, button:hover, button:active, button:focus, input:focus, input:active, textarea, textarea:focus, textarea:active {
  text-decoration: none;
  outline: none;
}

input, textarea {
  outline: none;
  text-decoration: none;
  appearance: none;
  border-radius: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

p {
  margin: 0;
  padding: 0;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 3px;
  
}
 
::-webkit-scrollbar-track {
  background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.06066176470588236) 45%, rgba(255,255,255,0.5) 45%, rgba(255,255,255,0.5) 55%, rgba(255,255,255,0) 55%, rgba(255,255,255,0) 100%);
  
}
 
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.7);
}

a, span, label, li, div {
  -webkit-tap-highlight-color: transparent;
}
