.default-preloader {
  .letter-b {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
  }

  .letter-e {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
  }

  .letter-f {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
  }

  .letter-a {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .letter-r {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
  }

  .letter-i {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 1.4s;
    animation-fill-mode: forwards;
  }

  .letter-n {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 1.6s;
    animation-fill-mode: forwards;
  }

  .letter-g {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 1.8s;
    animation-fill-mode: forwards;
  }

  .word-app {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 2.2s;
    animation-fill-mode: forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
