.api-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    font-size: 80px;
  }
  p {
    font-size: 20px;
  }
}
