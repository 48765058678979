.num-pad {
  .dots {
    display: flex;
    justify-content: center;
    .dot {
      width: 12px;
      height: 12px;
      margin: 12px;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      background-color: transparent;
      &.active {
        background-color: #FFFFFF;
      }
    }
  }
  .pads {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 210px;
    margin: 0 auto;
    .spacer {
      width: 50px;
      height: 50px;
      margin: 10px;
    }
    .pad {
      cursor: pointer;
      display: flex;
      margin: 10px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      width: 50px;
      height: 50px;
      background: rgba(255,255,255, 0.2);
      svg {
        fill: currentColor;
      }
      &:active {
        background: rgba(255,255,255, 1);
        color: #323232;
      }
    }
  }
}
