.login-page {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .selected-username {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }

  .login-page__footer {
    margin-bottom: 15px;
    font-size: 14px;
    @media (min-width: 432px) {
      margin-bottom: 68px;
      font-size: 16px;
    }

    a {
      color: #909090;
      text-decoration: underline;
    }
  }
}

.logo-big {
  & > img {
    margin: 14px auto;
    width: 70px;
    @media (min-width: 432px) {
      width: auto;
      margin: 40px auto;
    }
  }
}

.login-form {
  width: auto;
  margin: 0 15px;
  @media (min-width: 432px) {
    width: 530px;
    margin: 0 auto;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .button {
    display: block;
    width: 100%;
    padding: 14px 24px;
    margin-top: 30px;
  }
}

.form-input {
  padding: 20px;
  color: #fff;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}
