.cluster-marker {
  color: #fff;
  background: #98ccdb;
  border-radius: 50%;
  // padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-map-button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
  border-radius: 2px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    .svg-circle {
      fill: rgb(102, 102, 102);
    }

    .svg-circle-stoke {
      stroke: rgb(102, 102, 102);
    }
  }

  &:hover {
    svg {
      .svg-circle {
        fill: rgb(51, 51, 51);
      }

      .svg-circle-stoke {
        stroke: rgb(51, 51, 51);
      }
    }
  }

  &:active {
    svg {
      .svg-circle {
        fill: rgb(17, 17, 17);
      }

      .svg-circle-stoke {
        stroke: rgb(17, 17, 17);
      }
    }
  }
}
