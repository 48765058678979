// SHAME - START 
.block-adress {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5em;
}

.block-parent-checkbox {
  margin-bottom: 20px;
}

.block-row-item {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.block-back {
  display: flex;
  align-items: center;

  &_single {
    width: 50px;
    height: 50px;
    background: rgba(50, 50, 50, 0.3);
    justify-content: center;
    border-radius: 50%;
  }
}

.block-start {
  display: flex;
  justify-content: space-between;
  height: 100%;

  > a {
    width: 100%;
    &:first-child {
      display: none;
    }
    @media (min-width: 432px) {
      &:first-child {
        display: block;
        margin-right: 15px;
      }
      &:last-child {
        margin-left: 15px;
      }
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    &.block-start__item-befaring {
      background: url(./images/start1.jpg) no-repeat center / cover;
    }

    &.block-start__item-visningsliste {
      background: url(./images/start2.jpg) no-repeat center / cover;
    }
  }

  &__title {
    margin-top: 30px;
  }

  &__box {
    text-align: center;
  }
}

.map {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.l-grid__block {

  // -------- style for noUislider - start --------
  .noUi-horizontal .noUi-handle {
    padding: 15px;
    top: 2px;
    right: -20px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;

    &:focus {
      outline: none;
    }

    &:before {
      content: '';
      display: block;
      width: 9px;
      height: 25px;
      position: absolute;
      left: 11px;
      top: 0;
    }
  }

  .noUi-horizontal {
    height: 1px;
  }

  .noUi-handle:before, .noUi-handle:after {
    display: none;
  }

  .noUi-target {
    margin: 34px 0 13px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
    padding-right: 5px;
    border: none;
    border-radius: 0;
  }

  .noUi-connect {
    background: #fff;
    height: 1px;
  }

  .noUi-connects {
    border-radius: 0;
  }

  .noUi-tooltip {
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 150%;
    border-radius: 0;
    background: transparent;
    padding: 0;
  }

  .noUi-horizontal .noUi-origin > .noUi-tooltip {
    bottom: 4px;
    // font-size: 12px;
  }

  .noUI-merged-tooltips .noUi-handle .noUi-tooltip {
    display: none;
  }

  .noUI-smooth-steps {
    .noUi-base {
      .noUi-origin {
        transition: 0.20s;
      }

      .noUi-connects {
        .noUi-connect {
          transition: 0.20s;
        }
      }
    }
  }

  .noUi-base, .noUi-connects {
    padding: 30px 0 10px;
    top: -15px;
  }

  //-------- style for noUislider - end -------
}

// SHAME- END
