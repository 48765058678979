.marker {
  position: relative;
  width: 26px;
  height: 26px;

  &__info-box {
    position: absolute;
    bottom: 22px;
    left: 26px;
    padding: 0 0 29px 28px;
    background: url("../App/images/marker-info-box-link.svg") left bottom no-repeat;
    z-index: 1;
  }

  &__label {
    background: #868686;
    font-size: 12px;
    padding: 8px 15px;
    white-space: nowrap;
  }

  &.current {
    width: 26px;
    height: 26px;

    .marker__info-box {
      bottom: 21px;
      left: 22px;
      padding: 0 0 29px 28px;
      background: url("../App/images/marker-info-box-link_light.svg") left bottom no-repeat;
      z-index: auto;
    }

    &.selected {
      .marker__label {
        background: #323232;
      }
      .marker__info-box {
        background: url("../App/images/marker-info-box-link.svg") left bottom no-repeat;
      }
    }
  }

  &.selected {
    z-index: 1;
    .marker__label {
      background: #1198D2;
    }
  }
}
