.is-clickable {
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-danger {
  color: #ed5252;
}

.text-success {
  color: #4caf50;
}

.text-muted {
  color: #cccccc;
}

.text-center {
  text-align: center;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.ml-2 {
  margin-left: 16px !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

.jcsb {
  justify-content: space-between;
}

.bg-solid {
  background: #323232;
}

.w-100 {
  width: 100%;
}

.position-relative {
  position: relative;
}

.flex-wrap {
  flex-wrap: wrap;
}
